.PageSections {
    display: flex;
    flex-direction: column;
    gap: var(--page-sections-gap);
}

/* small screen */
@media screen and (max-width: 1024px) {
    .PageSections {
        gap: var(--page-sections-gap--small-screen);
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .PageSections {
        gap: var(--page-sections-gap--tablet);
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .PageSections {
        gap: var(--page-sections-gap--phone);
    }
}