.content img {
  max-width: 100%;
  box-sizing: border-box;
  object-fit: cover;
}

/* tablet  */
@media screen and (max-width: 768px) {
  iframe {
    width: 100%;
  }
}

/* phone */
@media screen and (max-width: 480px) {
}