.Grid30pct30pct30pctAnd30pct70pct {
    display: grid;
    column-gap: var(--horizontal-gap);
    row-gap: var(--vertical-gap);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "grid-30pct-30pct-30pct--30pct-70pct__item-1  grid-30pct-30pct-30pct--30pct-70pct__item-2  grid-30pct-30pct-30pct--30pct-70pct__item-3"
        "grid-30pct-30pct-30pct--30pct-70pct__item-4  grid-30pct-30pct-30pct--30pct-70pct__item-5  grid-30pct-30pct-30pct--30pct-70pct__item-5"
        "grid-30pct-30pct-30pct--30pct-70pct__item-6  grid-30pct-30pct-30pct--30pct-70pct__item-7  grid-30pct-30pct-30pct--30pct-70pct__item-8";
}

/* small screen */
@media screen and (max-width: 1024px) {
    .Grid30pct30pct30pctAnd30pct70pct {
        column-gap: var(--horizontal-gap--small-screen);
        row-gap: var(--vertical-gap--small-screen);
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "grid-30pct-30pct-30pct--30pct-70pct__item-1  grid-30pct-30pct-30pct--30pct-70pct__item-2"
            "grid-30pct-30pct-30pct--30pct-70pct__item-3  grid-30pct-30pct-30pct--30pct-70pct__item-4"
            "grid-30pct-30pct-30pct--30pct-70pct__item-5  grid-30pct-30pct-30pct--30pct-70pct__item-5"
            "grid-30pct-30pct-30pct--30pct-70pct__item-6  grid-30pct-30pct-30pct--30pct-70pct__item-7"
            "grid-30pct-30pct-30pct--30pct-70pct__item-8  .";
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .Grid30pct30pct30pctAnd30pct70pct {
        column-gap: var(--horizontal-gap--tablet);
        row-gap: var(--vertical-gap--tablet);
        grid-template-columns: 1fr;
        grid-template-areas:
            "grid-30pct-30pct-30pct--30pct-70pct__item-1"
            "grid-30pct-30pct-30pct--30pct-70pct__item-2"
            "grid-30pct-30pct-30pct--30pct-70pct__item-3"
            "grid-30pct-30pct-30pct--30pct-70pct__item-4"
            "grid-30pct-30pct-30pct--30pct-70pct__item-5"
            "grid-30pct-30pct-30pct--30pct-70pct__item-6"
            "grid-30pct-30pct-30pct--30pct-70pct__item-7"
            "grid-30pct-30pct-30pct--30pct-70pct__item-8";
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .Grid30pct30pct30pctAnd30pct70pct {
        column-gap: var(--horizontal-gap--phone);
        row-gap: var(--vertical-gap--phone);
        grid-template-columns: 1fr;
        grid-template-areas:
            "grid-30pct-30pct-30pct--30pct-70pct__item-1"
            "grid-30pct-30pct-30pct--30pct-70pct__item-2"
            "grid-30pct-30pct-30pct--30pct-70pct__item-3"
            "grid-30pct-30pct-30pct--30pct-70pct__item-4"
            "grid-30pct-30pct-30pct--30pct-70pct__item-5"
            "grid-30pct-30pct-30pct--30pct-70pct__item-6"
            "grid-30pct-30pct-30pct--30pct-70pct__item-7"
            "grid-30pct-30pct-30pct--30pct-70pct__item-8";
    }
}