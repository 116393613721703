:root {
  --color-white: #FFFFFF;
  --color-black-dark: #271919;
  --color-grey-medium: #C4C4C4;
  --color-green-medium: #0096A5;
  --color-green-dark: #008795;
  --color-green-darkest: #006C77;

  --horizontal-gap: 32px;
  --horizontal-gap--small-screen: 30px;
  --horizontal-gap--tablet: 24px;
  --horizontal-gap--phone: 16px;

  --vertical-gap: 30px;
  --vertical-gap--small-screen: 28px;
  --vertical-gap--tablet: 23px;
  --vertical-gap--phone: 15px;

  --page-sections-gap: 80px;
  --page-sections-gap--small-screen: 75px;
  --page-sections-gap--tablet: 60px;
  --page-sections-gap--phone: 40px;

  --component-height: 370px;

  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-black-dark);
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../public/static/fonts/ALSHauss-Regular.ttf') format('truetype');
}


body {
  margin: 0;
  height: 100%;
}

@import url(./headings.css);

a {
  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-black-dark);
}

p {
  margin: 0;
  min-height: 1px;
}

p:not(:last-of-type) {
  margin-bottom: 30px;
}

/* small screen */
@media screen and (max-width: 1024px) {
  :root {
    font-size: 18px;
    line-height: 25px;
  }

  a {
    font-size: 18px;
    line-height: 25px;
  }

  p:not(:last-of-type) {
    margin-bottom: 28px;
  }
}

/* tablet  */
@media screen and (max-width: 768px) {
  :root {
    font-size: 15px;
    line-height: 21px;
  }

  a {
    font-size: 15px;
    line-height: 21px;
  }

  p:not(:last-of-type) {
    margin-bottom: 23px;
  }
}

/* phone */
@media screen and (max-width: 480px) {
  :root {
    font-size: 15px;
    line-height: 18px;
  }

  a {
    font-size: 15px;
    line-height: 18px;
  }

  p:not(:last-of-type) {
    margin-bottom: 15px;
  }
}