.ExpandableCard {
    padding: 27px;
    border: 1px solid var(--color-grey-medium);
    border-radius: 30px;
    display: inline-block;
    box-sizing: border-box;
    width: 70%;
}

/* small screen */
@media screen and (max-width: 1024px) {
    .ExpandableCard {
        width: 100%;
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .ExpandableCard {
        width: 100%;
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .ExpandableCard {
        width: 100%;
    }
}