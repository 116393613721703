.Horizontal70pct30pct {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    box-sizing: border-box;
    column-gap: var(--horizontal-gap);
    row-gap: var(--vertical-gap);
}

/* small screen */
@media screen and (max-width: 1024px) {
    .Horizontal70pct30pct {
        display: grid;
        grid-template-columns: 0.6fr 0.4fr;
        column-gap: var(--horizontal-gap--small-screen);
        row-gap: var(--vertical-gap--small-screen);
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .Horizontal70pct30pct {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: var(--horizontal-gap--tablet);
        row-gap: var(--vertical-gap--tablet);
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .Horizontal70pct30pct {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: var(--horizontal-gap--phone);
        row-gap: var(--vertical-gap--phone);
    }
}