.FloatingLink {
    border-radius: 100px;
    background: #0096A5;

    box-shadow: 1px 7px 20px 4px rgba(0, 0, 0, 0.25), 0px 0px 0px 5px #FFF;
    width: 130px;
    height: 130px;
    flex-shrink: 0;
    cursor: pointer;

    position: fixed;
    right: 70px;
    bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
}

.FloatingLinkIcon {
    width: 70px;
    height: 70px;
    transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
    transform: translate(0, 0)' : 'translate(90px, 0);
}

.FloatingLinkText {
    display: none;
    transition: all 400ms cubic-bezier(0.61, 1, 0.88, 1);
    transform: translate(0, 0)' : 'translate(90px, 0);
    max-width: 250px;
}

.FloatingLink:hover {
    width: auto;
    padding: 0 24px;
}

.FloatingLink:hover .FloatingLinkText {
    display: block !important;
}

/* phone */
@media screen and (max-width: 480px) {
    .FloatingLink {
        right: 10px;
        width: 80px;
        height: 80px;
    }

    .FloatingLinkIcon {
        width: 40px;
        height: 40px;
    }
}