.Grid100pctAnd30pct30pct30pct {
    display: grid;
    column-gap: var(--horizontal-gap);
    row-gap: var(--vertical-gap);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "grid-100pct--30pct-30pct-30pct__item-1  grid-100pct--30pct-30pct-30pct__item-1  grid-100pct--30pct-30pct-30pct__item-1"
        "grid-100pct--30pct-30pct-30pct__item-2  grid-100pct--30pct-30pct-30pct__item-3  grid-100pct--30pct-30pct-30pct__item-4";
}

/* small screen */
@media screen and (max-width: 1024px) {
    .Grid100pctAnd30pct30pct30pct {
        column-gap: var(--horizontal-gap--small-screen);
        row-gap: var(--vertical-gap--small-screen);
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "grid-100pct--30pct-30pct-30pct__item-1  grid-100pct--30pct-30pct-30pct__item-1"
            "grid-100pct--30pct-30pct-30pct__item-2  grid-100pct--30pct-30pct-30pct__item-3"
            "grid-100pct--30pct-30pct-30pct__item-4  .";
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .Grid100pctAnd30pct30pct30pct {
        column-gap: var(--horizontal-gap--tablet);
        row-gap: var(--vertical-gap--tablet);
        grid-template-columns: 1fr;
        grid-template-areas:
            "grid-100pct--30pct-30pct-30pct__item-1"
            "grid-100pct--30pct-30pct-30pct__item-2"
            "grid-100pct--30pct-30pct-30pct__item-3"
            "grid-100pct--30pct-30pct-30pct__item-4";
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .Grid100pctAnd30pct30pct30pct {
        column-gap: var(--horizontal-gap--phone);
        row-gap: var(--vertical-gap--phone);
        grid-template-columns: 1fr;
        grid-template-areas:
            "grid-100pct--30pct-30pct-30pct__item-1"
            "grid-100pct--30pct-30pct-30pct__item-2"
            "grid-100pct--30pct-30pct-30pct__item-3"
            "grid-100pct--30pct-30pct-30pct__item-4";
    }
}