.ListItem {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    box-sizing: border-box;
}

.ListItem__bullet {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    border-radius: 50%;
    position: relative;
    top: 4px;
    box-sizing: border-box;
}

/* small screen */
@media screen and (max-width: 1024px) {
    .ListItem {
        gap: 18px;
    }
}

/* tablet  */
@media screen and (max-width: 768px) {
    .ListItem {
        gap: 12px;
    }
}

/* phone */
@media screen and (max-width: 480px) {
    .ListItem {
        gap: 10px;
    }
}