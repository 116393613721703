.grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 32px;
    box-sizing: border-box;
}

.grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    box-sizing: border-box;
}