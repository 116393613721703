.menu-dropdown {
    display: inline-flex;
    flex-direction: column;
    position: relative;
}

/* BUTTON */

.menu-dropdown__button {
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black-dark);
    display: flex;
    gap: 4px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
}

.menu-dropdown__button:hover {
    color: var(--color-green-medium);
}

.menu-dropdown__button:focus-visible {
    border-bottom: 1px solid var(--color-green-medium);
    outline: 2px solid var(--color-green-medium);
    outline-offset: 2px;
    border-radius: 4px;
}

/* DROPDOWN */

.menu-dropdown__dropdown {
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 26px;
    z-index: 1000;
    min-width: 390px;
}

.menu-dropdown__link {
    font-family: 'ALS Hauss';
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black-dark);
    white-space: nowrap;

}

.menu-dropdown__link:hover {
    text-decoration: underline;
    color: var(--color-green-medium);
}

.menu-dropdown__link:focus-visible {
    color: var(--color-green-medium);
    text-decoration: underline;
    outline: 2px solid var(--color-green-medium);
    outline-offset: 2px;
    border-radius: 4px;
}

.menu-dropdown__link--current {
    color: var(--color-green-medium);
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {
    .menu-dropdown__dropdown {
        position: fixed;
        padding: 14px;
        left: 0 !important;
        right: 0 !important;
        top: 154px;

        /* background-color: red; */
        min-width: auto !important;
        /* max-width: 300px; */
    }

    .menu-dropdown__link {
        font-size: 14px;
        /* max-width: 64px !important; */
        white-space: normal !important;

    }
}