h1 {
  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  color: var(--color-black-dark);
}

h2 {
  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 54px;
  color: var(--color-black-dark);
}

h3 {
  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: var(--color-black-dark);
}

h4 {
  font-family: 'ALS Hauss';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-black-dark);
}

/* small screen */
@media screen and (max-width: 1024px) {}

/* tablet  */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 49px;
    line-height: 55px;
  }

  h2 {
    font-size: 36px;
    line-height: 41px;
  }

  h3 {
    font-size: 24px;
    line-height: 33px;
  }

  h4 {
    font-size: 22px;
    line-height: 24px;
  }
}

/* phone */
@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
    line-height: 45px;
  }

  h2 {
    font-size: 26px;
    line-height: 32px;
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}